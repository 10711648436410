import { createContext } from 'react';
import type {
  RouterEventArgs,
  RouterEventName
} from './RouterEventManagerContext';

/**
 * A function that dispatches a router event.
 */
export type RouterEventDispatcher = <T extends RouterEventName>(
  eventName: T,
  args: RouterEventArgs<T>
) => void;

export const RouterEventDispatcherContext =
  createContext<RouterEventDispatcher>(() => {});
RouterEventDispatcherContext.displayName = 'RouterEventDispatcherContext';
