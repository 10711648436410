'use client';

import { FunctionComponent, useEffect } from 'react';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { datadogRum } from '@datadog/browser-rum';
import { useLocale } from '@/react/hooks/useLocale';

/**
 * A component for holding meta data added to the head.
 */
export const Datadog: FunctionComponent = () => {
  const { language, country } = useLocale();
  const dataDogConfig = ConfigurationService.getConfig(
    'datadog',
    language,
    country
  );
  const isDataDogActive = dataDogConfig.getSetting('enabled').value;

  useEffect(() => {
    if (isDataDogActive && !(process.env.NEXT_PUBLIC_APP_ENV === "dev")) {
      const clientToken = dataDogConfig.getSetting('clientToken').value;
      const applicationId = dataDogConfig.getSetting('applicationId').value;
      const sessionSampleRate =
        dataDogConfig.getSetting('sessionSampleRate').value;
      const sessionReplaySampleRate = dataDogConfig.getSetting(
        'sessionReplaySampleRate'
      ).value;
      const env = dataDogConfig.getSetting('env').value;
      const service = dataDogConfig.getSetting('service').value;
      const trackUserInteractions = dataDogConfig.getSetting(
        'trackUserInteractions'
      ).value;
      const trackResources = dataDogConfig.getSetting('trackResources').value;
      const trackFrustrations =
        dataDogConfig.getSetting('trackFrustrations').value;
      const trackLongTasks = dataDogConfig.getSetting('trackLongTasks').value;
      const defaultPrivacyLevel = dataDogConfig.getSetting(
        'defaultPrivacyLevel'
      ).value;
      const silentMultipleInit =
        dataDogConfig.getSetting('silentMultipleInit').value;
      const useSecureSessionCookie = dataDogConfig.getSetting(
        'useSecureSessionCookie'
      ).value;
      const version = dataDogConfig.getSetting('version').value;
      const allowedTracingUrls = [
        {
          match: dataDogConfig.getSetting('allowedTracingUrlsMatch').value,
          propagatorTypes: dataDogConfig.getSetting('propagatorTypes').value
        }
      ];

      datadogRum.init({
        clientToken,
        applicationId,
        sessionSampleRate,
        sessionReplaySampleRate,
        env,
        service,
        trackUserInteractions,
        trackResources,
        trackFrustrations,
        trackLongTasks,
        defaultPrivacyLevel,
        silentMultipleInit,
        useSecureSessionCookie,
        version,
        allowedTracingUrls
      });

      datadogRum.startSessionReplayRecording();
    }
  }, []);

  return null;
};
