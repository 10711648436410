'use client';

import { Partytown } from '@builder.io/partytown/react';
import Script from 'next/script';
import { FunctionComponent, PropsWithChildren, memo, useEffect } from 'react';

export interface IScriptProps extends PropsWithChildren {
  /**
   * Identifier for the tag or component.
   */
  id?: string;
  /**
   * String that will contain the script we want to load.
   */
  src?: string;
  /**
   * Strategies that the `<Script>` from Next can use.
   * @see https://nextjs.org/docs/pages/api-reference/components/script
   */
  strategy?:
    | 'afterInteractive'
    | 'lazyOnload'
    | 'beforeInteractive'
    | 'worker'
    | undefined;
  /**
   * Determines if we want to use the `<Script>` component from Next. Defaults to `false`.
   * @see https://nextjs.org/docs/pages/api-reference/components/script#strategy
   */
  useNext?: boolean;
  /** Sets or retrieves the MIME type for the associated scripting engine. */
  type?: string;
  /**
   * Code that runs once after the script has finished loading in order to instantiate content or call a function.
   * Can only be used when 'useNext' is set to true.
   * @see https://nextjs.org/docs/pages/api-reference/components/script#onload
   */
  onLoad?: () => void;
  /**
   * Code that runs after the script has finished loading and every time the component is mounted.
   * Can only be used when 'useNext' is set to true.
   * @see https://nextjs.org/docs/pages/api-reference/components/script#onerror
   */
  onReady?: () => void;
  /**
   * Code to catch errors.
   * Can only be used when 'useNext' is set to true.
   * @param err - Generic error object.
   * @see https://nextjs.org/docs/pages/api-reference/components/script#onerror
   */
  onError?: (err: Error) => void;
}

/**
 * With some external scripts/iframes (eg Gladly and Locally) the iframe experience that renders will disappear if you
 * navigate away from the page, then use the browser buttons to go back to the page.
 * It won’t rerender until the page is manually refreshed.
 * We needed to explore a way to manage this so pages like the help center don't appear broken when a consumer is
 * navigating around the site using the browser forward/back buttons.
 *
 * Partytown is a library It allows code executed from the web worker to access DOM synchronously.
 * The benefit from this is that third-party scripts can continue to work exactly how they’re coded.
 *
 * @see https://partytown.builder.io/how-does-partytown-work
 */
const ScriptLoader: FunctionComponent<IScriptProps> = memo(
  ({
    id,
    src,
    strategy,
    useNext = false,
    type,
    onLoad,
    onReady,
    onError,
    children
  }) => {
    useEffect(() => {
      if (!useNext) {
        const newScript = document.createElement('script');
        newScript.src = src ?? '';
        newScript.async = true;
        document.body.appendChild(newScript);
      }
    }, []);

    if (useNext) {
      return (
        <Script
          id={id}
          strategy={strategy}
          src={src}
          type={type}
          onLoad={onLoad}
          onReady={onReady}
          onError={onError}
        >
          {children}
        </Script>
      );
    }
    return <Partytown forward={['dataLayer.push']} />;
  }
);

export default ScriptLoader;
