'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { memo, useContext, useEffect, useState, type FC } from 'react';
import { RouterEventDispatcherContext } from './internal/RouterEventDispatcherContext';

/**
 * A component which hooks into pathname and search params changes to dispatch router events.
 *
 * This is primarily used to shim the `routeChangeStart`
 * and `routeChangeComplete` events into App Router.
 *
 * **NOTE**: This does not work with shallow routing on the Pages Router.
 */
export const RouterEvents: FC = memo(function RouterEvents() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const routerEventDispatcher = useContext(RouterEventDispatcherContext);

  const currentPathQuery = `${pathname}?${searchParams}`;

  const [prevPathQuery, setPrevPathQuery] = useState<string>(currentPathQuery);

  useEffect(() => {
    if (prevPathQuery !== currentPathQuery) {
      setPrevPathQuery(currentPathQuery);
      routerEventDispatcher('routeChangeStart', [currentPathQuery]);
    } else {
      routerEventDispatcher('routeChangeComplete', [currentPathQuery]);
    }
  }, [currentPathQuery, prevPathQuery, routerEventDispatcher]);

  return null;
});
