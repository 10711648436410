'use client';

import { useContext, FunctionComponent } from 'react';

import { useErrorAlert } from '@/react/hooks/useErrorAlert';
import { ErrorAlert } from '@/react/components/core-ui/ErrorAlert';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import Notification from '../Notification';
import { NotificationContext } from '../../controller';

import S from './styles.base.module.scss';

/**
 * Component wraps notification related content (e.g. error messages, success messages, add to cart messages.).
 */
export const NotificationBanner: FunctionComponent = () => {
  const { notificationQueue } = useContext(NotificationContext);
  const { error, closeAlert } = useErrorAlert();
    const isProd = (process.env.NEXT_PUBLIC_APP_ENV === "prod");
  return (
    <div className={S.notificationsBanner}>
      <Notification
        notification={
          notificationQueue.length > 0 ? notificationQueue[0] : null
        }
      />
      {!!error && (
        <ErrorAlert
          error={error}
          onClose={closeAlert}
          obscureMessage={isProd}
        />
      )}
    </div>
  );
};
