'use client';

import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useDeferredValue,
  useMemo,
  useState
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ITopError, type TopErrorLike } from '../ITopError';
import { TopErrorListContext } from '../TopErrorListContext';
import { TopErrorManagerContext } from '../TopErrorManagerContext';

/**
 * A context provider that manages {@link ITopError top errors}.
 *
 * Since this is primarily for debugging purposes, this component
 * must not be used in production environments.
 */
const NonProdTopErrorProvider: FC<PropsWithChildren> = ({ children }) => {
  const [errors, setErrors] = useState<Array<ITopError>>([]);

  const removeError = useCallback((id: string): void => {
    setErrors((errors) => errors.filter((err) => err.id !== id));
  }, []);

  const addError = useCallback((error: TopErrorLike): void => {
    setErrors((errors) => [
      // add newest error to the front
      { id: uuidv4(), error },
      // to prevent memory and performance issues, only keep the last 20 errors
      ...errors.slice(0, 19)
    ]);
  }, []);

  const clearErrors = useCallback((): void => {
    setErrors([]);
  }, []);

  const context = useMemo(
    () => ({ addError, removeError, clearErrors }),
    [addError, removeError, clearErrors]
  );

  const deferredErrors = useDeferredValue(errors);

  return (
    <TopErrorListContext.Provider value={deferredErrors}>
      <TopErrorManagerContext.Provider value={context}>
        {children}
      </TopErrorManagerContext.Provider>
    </TopErrorListContext.Provider>
  );
};

/**
 * A context provider that manages {@link ITopError top errors}.
 * Since this is primarily for debugging purposes, this will do nothing in production.
 * @param props - The props to pass to the provider.
 * @returns A React node.
 */
export const TopErrorProvider: FC<PropsWithChildren> = (process.env.NEXT_PUBLIC_APP_ENV === "prod")
  ? ({ children }) => children
  : NonProdTopErrorProvider;
