'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { Button } from '@/react/components/core-ui/Button';
import { msg } from '@/services/isomorphic/I18NService';
import { isNullOrEmpty } from '@/utils/null-utils';
import { AnimatePresence, motion } from 'framer-motion';
import { memo, useState, type FC, type PropsWithChildren } from 'react';
import type { RenderError } from '../../RenderError';
import { ComponentChain } from '../ComponentChain';
import S from './styles.module.scss';
import { general_errors_renderAlerts_seeDetails } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_seeDetails";
import { general_errors_renderAlerts_timestampTooltip } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_timestampTooltip";
import { general_errors_renderAlerts_timestamp } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_timestamp";
import { general_errors_renderAlerts_errorName } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_errorName";
import { general_errors_renderAlerts_componentStack } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_componentStack";
import { general_errors_renderAlerts_hoverToReveal } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_hoverToReveal";
import { general_errors_renderAlerts_stackTrace } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_stackTrace";
import { general_errors_renderAlerts_errorMsg } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_errorMsg";

export interface IRenderErrorDetailsAccordionProps {
  /**
   * The render error to display details for.
   */
  renderError: RenderError;
  /**
   * Whether the accordion should be opened by default.
   * @default false
   */
  defaultOpened?: boolean;
}

/**
 * An accordion that displays details about a render error.
 */
export const RenderErrorDetailsAccordion: FC<
  IRenderErrorDetailsAccordionProps
> = ({ renderError, defaultOpened = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpened);

  const { componentStack, timestamp, stack, name, message, error } =
    renderError;

  const formattedTimestamp = timestamp.toFixed(0) + 'ms';

  return (
    <div role="group" className={S.accordion}>
      <button
        type="button"
        className={S.accordionHeader}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={classes(S.accordionTitle, isOpen ? S.open : null)}>
          {msg(general_errors_renderAlerts_seeDetails)}
        </div>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            className={S.accordionContent}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 }
            }}
          >
            <div
              className={S.section}
              title={msg(general_errors_renderAlerts_timestampTooltip)}
            >
              <span className={S.sectionTitle}>
                {msg(general_errors_renderAlerts_timestamp)}:
              </span>
              {formattedTimestamp}
            </div>

            <div className={S.section}>
              <span className={S.sectionTitle}>
                {msg(general_errors_renderAlerts_errorName)}:
              </span>
              {name}
            </div>

            <ErrorMessageLine>
              {error instanceof AggregateError ? (
                <AggregateErrorMessagesAccordion error={error} />
              ) : (
                message
              )}
            </ErrorMessageLine>

            {componentStack && (
              <div className={S.section}>
                <div
                  role="menuitem"
                  className={classes(S.componentChainClamp)}
                  // this is selectable in order to make the reveal
                  // work with keyboard navigation
                  tabIndex={0}
                >
                  <div>
                    <span className={S.sectionTitle}>
                      {msg(general_errors_renderAlerts_componentStack)}
                    </span>
                    <span className={S.hoverHint}>
                      ({msg(general_errors_renderAlerts_hoverToReveal)})
                    </span>
                  </div>
                  <ComponentChain chain={componentStack} />
                </div>
              </div>
            )}

            {!isNullOrEmpty(stack) && (
              <div className={S.section}>
                <span className={S.sectionTitle}>
                  {msg(general_errors_renderAlerts_stackTrace)}
                </span>
                <div className={S.stackTraceWrapper}>
                  <pre className={S.stackTrace}>{stack}</pre>
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

/**
 * A component that renders a "line" for the render error message.
 * If there are no `children`, this component will not render.
 */
const ErrorMessageLine = memo(function ErrorMessageLine({
  children
}: PropsWithChildren) {
  // if children are falsy or `true`, don't render anything
  if (!Boolean(children) || children === true) return null;

  return (
    <div className={S.section} style={{ display: 'flex' }}>
      <span className={S.sectionTitle}>
        {msg(general_errors_renderAlerts_errorMsg)}:
      </span>
      {children}
    </div>
  );
});

/**
 * A component that renders a disclosure (accordion) of error messages.
 * @param props - The props to pass to the component.
 */
const AggregateErrorMessagesAccordion = memo(
  ({ error }: { error: AggregateError }) => {
    const { message, errors } = error;

    const filteredErrors = errors
      // filter errors to only non-empty strings or `Error` instances with non-empty messages
      .filter(
        (err: unknown): err is Error | string =>
          (err instanceof Error && !!err.message) ||
          (typeof err === 'string' && !!err)
      )
      // only show the first 50 errors for performance reasons
      .slice(0, 50);

    // if there are no sub-errors to show, just show the aggregate error message
    if (filteredErrors.length === 0) {
      return <>{message}</>;
    }

    return (
      <details className={S.aggregateDetails} open>
        <summary>{message}</summary>
        <ul className={S.aggregateDetailsBody}>
          {filteredErrors.map((error) => (
            // eslint-disable-next-line react/jsx-key -- The default key is adequate for now.
            <li>
              {typeof error === 'string' ? (
                error
              ) : (
                <Button
                  variant="text"
                  className={S.subErrorBtn}
                  // eslint-disable-next-line no-console -- This is for debugging purposes.
                  onClick={() => console.dir(error)}
                  title="Click to log the error to the console"
                >
                  {error.message}
                </Button>
              )}
            </li>
          ))}
        </ul>
      </details>
    );
  }
);
