'use client';

import Script from 'next/script';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { FunctionComponent } from 'react';
import { useLocale } from '@/react/hooks/useLocale';

/**
 * The Osano integration code script.
 */
export const OsanoScript: FunctionComponent = () => {
  const { language, country } = useLocale();
  const config = ConfigurationService.getConfig('osano', language, country);
  const customerID = config.getSetting('customerID').value;
  const configurationID = config.getSetting('configurationID').value;
  const enabled = config.getSetting('enabled').value;

  return enabled ? (
    <Script
      strategy="beforeInteractive"
      src={`https://cmp.osano.com/${customerID}/${configurationID}/osano.js`}
    />
  ) : null;
};
