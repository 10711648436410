'use client';

import { MutableRefObject, SyntheticEvent, useEffect } from 'react';

/**
 * Trigger a callback whenever the user clicks outside of the given element.
 *
 * @param elementRef - The reference to the element. Whenever a click outside of this element is detected, `onClickedOutside` is called.
 * @param onClickedOutside - The callback to call, when a click outside of element referenced by `elementRef` is detected.
 */
export function useOnClickedOutside(
  elementRef: MutableRefObject<HTMLElement>,
  onClickedOutside: (e?: SyntheticEvent) => void
): void {
  useEffect(() => {
    const callback = (evt: MouseEvent): void => {
      if (!elementRef.current?.contains(evt.target as Node)) {
        onClickedOutside();
      }
    };

    window.addEventListener('click', callback);
    return () => {
      window.removeEventListener('click', callback);
    };
  }, [onClickedOutside, elementRef]);
}
