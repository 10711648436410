'use client';

import {
  FunctionComponent,
  PropsWithChildren,
  useDeferredValue,
  useEffect
} from 'react';

import { useRouter } from '@/react/utils/router-utils/useRouter';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { ErrorAlertContext } from '../ErrorAlertContext';
import { useErrorAlertProviderValue } from '../ErrorAlertContext/useErrorAlertProviderValue';

/**
 * This component provides the top-level context necessary for displaying error alerts.
 * It also sets up window-level event listeners to detect and display unhandled errors.
 */
export const GlobalErrorAlertProvider: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  const value = useErrorAlertProviderValue();
  const { alert, closeAlert } = value;

  const router = useRouter();

  useEffect(
    function closeErrorOnNavigate() {
      router.events.on('routeChangeStart', closeAlert);

      return () => {
        router.events.off('routeChangeStart', closeAlert);
      };
    },
    [closeAlert, router]
  );

  /**
   * This event listener helps us to catch any error that happens outside of rendering,
   * such as within a event handler like `onClick`, and display it in our `ErrorAlert` popup.
   */
  useEffect(() => {
    const errorListener = (event: ErrorEvent | PromiseRejectionEvent): void => {
      // Temporarily hide uncaught errors in production
      // TODO: Eventually remove this after soft launch
      const hideUncaughtErrors = ConfigurationService.getConfig(
        'ui'
      ).getSetting('global.hideUncaughtErrors').value;

      if (hideUncaughtErrors) {
        return;
      }

      const alertContent =
        event instanceof ErrorEvent ? event.error : event.reason;

      alert(alertContent);
      event.preventDefault();
    };

    window.addEventListener('error', errorListener);
    window.addEventListener('unhandledrejection', errorListener);

    return () => {
      window.removeEventListener('error', errorListener);
      window.removeEventListener('unhandledrejection', errorListener);
    };
  }, [alert]);

  const deferredValue = useDeferredValue(value);

  return (
    <ErrorAlertContext.Provider value={deferredValue}>
      {children}
    </ErrorAlertContext.Provider>
  );
};
