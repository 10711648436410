'use client';

import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { useContext, type FC } from 'react';
import { BasicErrorBoundary } from '../../boundaries/BasicErrorBoundary';
import { TopErrorAlert } from '../TopErrorAlert';
import { TopErrorListContext } from '../TopErrorListContext';
import S from './styles.module.scss';

/**
 * Renders a list of alerts for top-level errors.
 */
const NonProdTopErrorAlertList: FC = () => {
  const errors = useContext(TopErrorListContext);

  if (errors.length === 0) return null;

  return (
    <BasicErrorBoundary fallback={null}>
      <div className={S.container}>
        {errors.map((error) => (
          <TopErrorAlert key={error.id} topError={error} />
        ))}
      </div>
    </BasicErrorBoundary>
  );
};

/**
 * Renders a list of alerts for top-level errors.
 * @returns A React node.
 */
export const TopErrorAlertList: FC = (process.env.NEXT_PUBLIC_APP_ENV === "prod")
  ? () => null
  : NonProdTopErrorAlertList;
