'use client';

import { FunctionComponent } from 'react';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { useLocale } from '@/react/hooks/useLocale';
import Head from 'next/head';
import ScriptLoader from '../../utility/ScriptLoader';

/**
 * Adds the dynamic yield scripts to manage dynamic yield analytics.
 */
const DynamicYield: FunctionComponent = () => {
  const { language, country } = useLocale();
  const config = ConfigurationService.getConfig(
    'dynamicYield',
    language,
    country
  );

  const isDYEnabled = config.getSetting('isDYEnabled').value;
  const isDYClientEnabled = config.getSetting('isDYClientEnabled').value;
  const siteID = config.getSetting('siteID').value;

  return (
    <>
      {isDYEnabled && isDYClientEnabled && (
        <>
          <Head>
            <link rel="preconnect" href="//cdn.dynamicyield.com" />
            <link rel="preconnect" href="//st.dynamicyield.com" />
            <link rel="preconnect" href="//rcom.dynamicyield.com" />
            <link rel="dns-prefetch" href="//cdn.dynamicyield.com" />
            <link rel="dns-prefetch" href="//st.dynamicyield.com" />
            <link rel="dns-prefetch" href="//rcom.dynamicyield.com" />
          </Head>
          <ScriptLoader
            strategy="afterInteractive"
            type="text/javascript"
            src={`//cdn.dynamicyield.com/api/${siteID}/api_static.js`}
            useNext
          />
          <ScriptLoader
            strategy="afterInteractive"
            type="text/javascript"
            src={`//cdn.dynamicyield.com/api/${siteID}/api_dynamic.js`}
            useNext
          />
        </>
      )}
    </>
  );
};

export default DynamicYield;
