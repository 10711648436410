import { insertBetween } from '@/utils/array-utils';
import { memo } from 'react';
import S from './styles.module.scss';

export interface IComponentChainProps {
  /**
   * The chain of component names to render.
   */
  chain: ReadonlyArray<string>;
}

/**
 * A component that renders a chain of component names
 * as a series of pills with arrows between them.
 */
export const ComponentChain = memo<IComponentChainProps>(
  function ComponentChain({ chain }) {
    // Render each component name as a pill.
    const list = chain.map((componentName, index) => (
      // eslint-disable-next-line react/no-array-index-key -- The descendant components are static and will not change.
      <span key={index} className={S.pill}>
        {componentName}
      </span>
    ));

    // Insert left-arrows between each component name to show the hierarchy.
    const listWithArrows = insertBetween(list, () => ' ← ');

    return <>{listWithArrows}</>;
  }
);
