'use client';

import { Icon, IconTypes } from '@/react/components/core-ui/Icon';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import S from './styles.module.scss';

export interface IErrorAlertProps extends PropsWithChildren {
  /**
   * The title of the error alert.
   * Can be any valid React node.
   */
  title: ReactNode;
  /**
   * The subtitle of the error alert.
   * Can be any valid React node.
   */
  subtitle?: ReactNode;
  /**
   * The action to perform when the alert is closed.
   */
  onClose?: () => void;
}

/**
 * A developer-facing generic error alert component.
 */
export const DevErrorAlert: FC<IErrorAlertProps> = ({
  title,
  onClose,
  subtitle,
  children
}) => {
  return (
    <div className={S.container}>
      <div className={S.header}>
        <div className={S.headerLeft}>
          <Icon icon={IconTypes.Alert} className={S.alertIcon} />
          <div>
            <span className={S.title}>{title}</span>
            <div className={S.subtitle}>{subtitle}</div>
          </div>
        </div>

        <div className={S.headerRight}>
          {onClose && (
            <Icon
              onClick={onClose}
              className={S.closeIcon}
              icon={IconTypes.Close}
            />
          )}
        </div>
      </div>

      {children}
    </div>
  );
};
