import { FunctionComponent } from 'react';
import Image from 'next/image';

import Link from 'next/link';
import { IBasicNotification } from '../../types';

import S from './styles.module.scss';

export interface IBasicNotificationBodyProps {
  /** Basic notification to render. */
  notification: IBasicNotification;
}

/** Renders the body of an {@link IBasicNotification}. */
const BasicNotificationBody: FunctionComponent<IBasicNotificationBodyProps> = ({
  notification
}) => {
  const { image, title, body, links } = notification.content;

  return (
    <div className={S.basicNotification}>
      {image ? (
        <div className={S.imageContainer}>
          <Image
            alt={image.alt ?? ''}
            src={image.src}
            width={50}
            height={50}
            objectFit="contain"
          />
        </div>
      ) : null}

      <div className={S.textContainer}>
        {title ? <div className={S.title}>{title}</div> : null}
        <div className={S.body}>{body}</div>

        {links ? (
          <div className={S.linksContainer}>
            {links.map((l) => (
              <Link href={l.href} className={S.link} key={l.href}>
                {l.text}
              </Link>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BasicNotificationBody;
