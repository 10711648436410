'use client';

import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { INotification, IQueuedNotification } from '../types';

import type NotificationContext from './NotificationContext';

const defaultOptions = {
  duration: 108000
};

/**
 * Holds state and provides callbacks for notification management.
 * This controller is meant to be used as the value of the {@link NotificationContext}.
 */
export interface INotificationController {
  /** Queue that contains all notifications waiting to be displayed. */
  notificationQueue: Array<IQueuedNotification>;

  /**
   * Queues a new notification in the notification queue.
   * @param notification - Notification to queue.
   * @returns The unique identifier of the queued notification.
   */
  queueNotification: (notification: INotification) => string;

  /**
   * Deletes or dismisses a queued notification.
   * @param notificationID - The unique identifier of the {@link IQueuedNotification} to remove.
   */
  deleteNotification: (notificationID: string) => void;
}

/**
 * Holds state and provides callbacks for notification management.
 * Encapsulates the logic needed for the {@link NotificationContext}.
 *
 * @see The {@link Main Main Layout} to see it in action.
 *
 * @returns An {@link INotificationController} object.
 */
const useNotificationController = (): INotificationController => {
  // Notifications will be stored in this queue in order to be displayed.
  const [queue, setQueue] = useState<Array<IQueuedNotification>>([]);

  /** Delete/dismiss a notification. */
  const deleteNotification = useCallback((contentID: string) => {
    setQueue((prevQueue) =>
      prevQueue.filter((content) => content.uuid !== contentID)
    );
  }, []);

  /** Queue a new notification. */
  const queueNotification = useCallback(
    (notification: INotification) => {
      const { options } = notification;

      // Generate a new UUID for the queued notification.
      const notificationID = uuid();

      // Create the timeout that will delete the notification. This timeout
      // will run after the specified (or default) duration.
      const timeout = setTimeout(() => {
        deleteNotification(notificationID);
      }, options?.duration ?? defaultOptions.duration);

      // Create the `close` callback, which will manually dismiss the
      // notification if called.
      const close = (): void => {
        // Clear the timeout and...
        clearTimeout(timeout);

        // ...call deleteNotification with this notification's ID.
        deleteNotification(notificationID);
      };

      // Create the new queued notification...
      const newQueuedNotification: IQueuedNotification = {
        ...notification,
        uuid: notificationID,
        timeout,
        close
      };

      // ...and store it in the queue.
      setQueue((prevQueue) => [...prevQueue, newQueuedNotification]);

      return notificationID;
    },
    [deleteNotification]
  );

  return {
    notificationQueue: queue,
    queueNotification,
    deleteNotification
  };
};

export default useNotificationController;
