'use client';

import { msg, msgf } from '@/services/isomorphic/I18NService';
import { isNullOrEmpty } from '@/utils/null-utils';
import { memo, useCallback, useContext, useMemo } from 'react';
import { DevErrorAlert } from '../DevErrorAlert';
import type { ITopError } from '../ITopError';
import type { RenderError } from '../RenderError';
import { TopErrorManagerContext } from '../TopErrorManagerContext';
import { RenderErrorDetailsAccordion } from './RenderErrorDetailsAccordion';
import { general_errors_renderAlerts_title_inHydrationProd } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_title_inHydrationProd";
import { general_errors_renderAlerts_title_inRenderProd } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_title_inRenderProd";
import { general_errors_renderAlerts_dateTimeTooltip } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_dateTimeTooltip";
import { general_errors_renderAlerts_title_inHydrationDev } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_title_inHydrationDev";
import { general_errors_renderAlerts_title_inRenderDev } from "@/lang/__generated__/ahnu/general_errors_renderAlerts_title_inRenderDev";

export interface IRenderErrorAlertProps {
  /**
   * The top error to be displayed.
   */
  topError: ITopError<RenderError>;
  /**
   * Whether the accordion should be opened by default.
   * @default false
   */
  defaultOpened?: boolean;
}

/**
 * Renders an error alert with render error details.
 */
export const RenderErrorAlert = memo<IRenderErrorAlertProps>(
  function RenderErrorAlert({ topError, defaultOpened = false }) {
    const { removeError } = useContext(TopErrorManagerContext);

    const { id, error: renderError } = topError;
    const { componentStack, date, inHydration } = renderError;
    const componentName = componentStack?.[0];

    const title = !isNullOrEmpty(componentName)
      ? inHydration
        ? msgf(general_errors_renderAlerts_title_inHydrationDev, {
            componentName
          })
        : msgf(general_errors_renderAlerts_title_inRenderDev, {
            componentName
          })
      : inHydration
        ? msg(general_errors_renderAlerts_title_inHydrationProd)
        : msg(general_errors_renderAlerts_title_inRenderProd);

    const onClose = useCallback(() => {
      removeError(id);
    }, [id, removeError]);

    const formattedDate = useMemo(
      () =>
        new Intl.DateTimeFormat(undefined, {
          dateStyle: 'full',
          timeStyle: 'long'
        }).format(date),
      [date]
    );

    return (
      <DevErrorAlert
        title={title}
        onClose={onClose}
        subtitle={
          <span title={msg(general_errors_renderAlerts_dateTimeTooltip)}>
            at {formattedDate}
          </span>
        }
      >
        <RenderErrorDetailsAccordion
          renderError={renderError}
          defaultOpened={defaultOpened}
        />
      </DevErrorAlert>
    );
  }
);
