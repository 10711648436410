'use client';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { FunctionComponent } from 'react';
import { useLocale } from '@/react/hooks/useLocale';
import ScriptLoader from '../../utility/ScriptLoader';

/**
 * The GTM initializer for _app. This is a standard block provided by the GTM
 * container. It should normally be run in the head and have a fallback in GTMBody
 * (_document). Since NEXT 11.00 the recommended way is to use the NEXT Script tag
 * to place it in the body.
 * @see https://morganfeeney.com/how-to/integrate-google-tag-manager-with-next-js
 */
const GTM: FunctionComponent = () => {
  const locale = useLocale();

  const tagManagementConfig = ConfigurationService.getConfig(
    'tagManagement',
    locale.language,
    locale.country
  );

  const enableGTM = tagManagementConfig.getSetting('enableGTM').value;
  const tagId = tagManagementConfig.getSetting('tagId').value;

  return (
    <>
      {enableGTM && (
        <ScriptLoader
          useNext
          id="google-tag-manager"
          strategy="afterInteractive"
        >
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${tagId}');`}
        </ScriptLoader>
      )}
    </>
  );
};

export default GTM;
